export default [
  {
    hdc: {
      dst: ['sD+rUnoVGWR9fYin3Lko0g=='],
      port: {
        http: ['30071'],
        https: ['30243'],
      },
    },
    business: null,
    name: 'zhengzhou',
  },
];
