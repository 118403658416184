import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice({
  name: 'root',
  initialState: {
    token: '1111'
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
      return state
    },
    clearToken: (state) => {
      state.token = ''
      return state
    }
  },
});

export const { setToken, clearToken } = counterSlice.actions;
export default counterSlice.reducer;
