import useHdcProgress from '@/store/HdcProgressStore';
import React from 'react';
import { FC } from 'react';

const createPolygonClipPath = (sides: number, size = 100) => {
  let points = [];
  for (let i = 0; i < sides; i++) {
    // 计算多边形顶点的角度
    const angle = ((2 * Math.PI) / sides) * i;

    // 计算顶点的 x 和 y 坐标
    const x = 50 + (size * Math.cos(angle)) / 2; // 加 50 是为了将多边形中心移动到容器的中心
    const y = 50 + (size * Math.sin(angle)) / 2; // 同上

    // 添加到点集合中
    points.push(`${x.toFixed(2)}% ${y.toFixed(2)}%`);
  }

  // 生成 clip-path 属性值
  return `polygon(${points.join(', ')})`;
};

// 三角形ClipPath
const TRIANGLE_CLIP_PATH = createPolygonClipPath(3);
const SIZE = '14px';
const ANIMATION_TIME = 1.2;
const ANIMATION = `lds-dual-ring ${ANIMATION_TIME}s linear infinite`;

interface PolygonProps {
  animation: boolean;
  color: string;
}
const Polygon: FC<PolygonProps> = ({ animation, color }) => {
  const style = {
    backgroundColor: color,
    aspectRatio: '1',
    width: SIZE,
    height: SIZE,
    clipPath: TRIANGLE_CLIP_PATH,
  } as React.CSSProperties;

  if (animation) {
    style.animation = ANIMATION;
  }

  return <div style={style}></div>;
};

const Loading = () => {
  const list = useHdcProgress((state) => state.recordList);
  return (
    <div className="loading-box">
      <div
        style={{
          display: 'flex',
          maxWidth: '50%',
          flexWrap: 'wrap',
        }}
      >
        {list.map((color, index) => {
          return (
            <Polygon
              key={index}
              color={color}
              animation={list.length == index + 1}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Loading;
