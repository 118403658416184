import { gql } from '@apollo/client';

export const QUERY_DOMAIN_QL = gql`
  query QueryDomain($env: String) {
    queryDomainV2(request: { cause: 0, type: 0, usage: "[0, 7]", env: $env }) {
      nodes {
        name
        hdc {
          port {
            http
            https
          }
          dst
        }
        business {
          dst {
            usage
            value
            port {
              http
              https
            }
          }
        }
      }
    }
  }
`;

export const REPORT_DOMAIN_QL = gql`
  query ReportDomain($nodes: String) {
    queryDomainV2(request: { cause: 1, type: 0, usage: "[0, 7]", nodes: $nodes }) {
      nodes {
        name
        hdc {
          port {
            http
            https
          }
          dst
        }
        business {
          dst {
            usage
            value
            port {
              http
              https
            }
          }
        }
      }
    }
  }
`;
