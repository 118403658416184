export default [
  {
    name: 'zhengzhou',
    hdc: {
      port: {
        http: ['30071'],
        https: ['30243'],
      },
      dst: ['sD+rUnoVGWR9fYin3Lko0g=='],
    },
    business: null,
  },
];
