import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    counter: 0
  },
  reducers: {
    increment: (state, action) => {
      console.log(action,'action')
      state.counter = state.counter + 1
      return state
    },
    decrement: (state) => {
      state.counter = state.counter - 1
      return state
    }
  },
});

export const { increment, decrement } = counterSlice.actions;
export default counterSlice.reducer;
