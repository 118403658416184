export default [
  {
    name: 'rps_chn_chd_hdc',
    business: null,
    hdc: {
      port: {
        http: ['80', '30071'],
        https: ['443', '30243'],
      },
      dst: [
        'aEENqxG+Z1xu9OvkDFOBpA==',
        'muzoikzitFV+G/qETWqY0eHkIpyfm/UUcDFArH0ORLw=',
        'EE+JM4JJSOoVfwvWrKVjtg==',
      ],
    },
  },
  {
    name: 'rps_chn_tw_hdc',
    business: null,
    hdc: {
      port: {
        http: ['80', '30071'],
        https: ['443', '30243'],
      },
      dst: [
        '9jWjbLsMSfGauoTXyePsnw==',
        'JTpOL0qBnNJcmNhm60TRSg==',
        'GgIrBvAXUP0aLHl60iQkGg==',
        'iTvtCG7KD2uOblq1RVO88Q==',
      ],
    },
  },
];
