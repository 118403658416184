import { HdcNodeInfo } from './HdcTypes';
import dev from './config/dev';
import prod from './config/prod';
import qa from './config/qa';
import qa2 from './config/qa2';
import pre from './config/pre';

const HDC_ENV = process.env.HDC_ENV as 'qa' | 'dev' | 'prod';

export const HDCNodeName = {
  chd: 'rps_chn_chd_hdc',
  tw: 'rps_chn_tw_hdc',
};

export const HDC_BUILTIN_INFO: Record<string, HdcNodeInfo[]> = {
  qa,
  dev,
  prod,
  pre,
  SecQA: qa2,
};

const OSS_FILE_DICT = {
  qa: ['/dev/bkwhcfg_rps_chn_zhengzhou_hdc_yzpay_qa.json'],
  dev: ['/dev/bkwhcfg_rps_chn_zhengzhou_hdc_yzpay_dev.json'],
  prod: [
    '/prod/bkwhcfg_rps_chn_chd_hdc_yzpay_prod.json',
    '/prod/bkwhcfg_rps_chn_tw_hdc_yzpay_prod.json',
  ],
  pre: [
    '/prod/bkwhcfg_rps_chn_chd_hdc_yzpay_pre.json',
    '/prod/bkwhcfg_rps_chn_tw_hdc_yzpay_pre.json',
  ],
  SecQA: ['/dev/bkwhcfg_rps_chn_zhengzhou_hdc_yzpay_SecQA.json'],
} as const;

const OSS_BASE_URL =
  'https://ha-yzpay-oss.oss-cn-chengdu.aliyuncs.com' as const;

export const HDC_BUILTIN_OSS_INFO = OSS_FILE_DICT[HDC_ENV].map(
  (filePath) => OSS_BASE_URL + filePath
);
