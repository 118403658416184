import React, { PropsWithChildren } from 'react';
import { ApolloError } from '@apollo/client';
import Loading from '@/tools/hdc/ui/Loading';

const addEventListener = window.addEventListener;

const saveError = (error: Error) => {
  const errorList = JSON.parse(localStorage.getItem('errorList') || '[]');
  const errorInfo = error.stack ?? String(error);
  errorList.push(errorInfo);
};

const loadError = () => {
  const errorList = JSON.parse(localStorage.getItem('errorList') || '[]');
  return errorList;
}

export const setupErrorListener = () => {
  addEventListener('error', (event) => {
    const e = event.error as Error;
    console.log('window on error 事件监听遇到问题 ↓');
    console.error(e);
    saveError(e);
  });
}

export class ErrorBoudary extends React.Component<PropsWithChildren> {
  state = {
    hasError: false,
    error: null,
  };
  constructor(props: PropsWithChildren) {
    super(props);
    const errorList = loadError();
    if (errorList.length > 0) {
      this.state = {
        hasError: true,
        error: errorList[errorList.length - 1],
      };
    }
    window.addEventListener('error', (event) => {
      console.log('event', event)
      this.setState({
        hasError: true,
        error: event.error,
      });
    });
    window.addEventListener('unhandledrejection', (event) => {
      console.log('event', event)
      if (event.reason instanceof ApolloError) {
        this.setState({
          hasError: true,
          error: event.reason,
        });
      }
    });
  }

  static getDerivedStateFromError(error: Error) {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    console.log('getDerivedStateFromError', error);
    return { hasError: true, error };
  }
  componentDidCatch(error: Error, errorInfo: any) {
    // 你同样可以将错误日志上报给服务器
    console.log('componentDidCatch', error, errorInfo);
  }
  render() {
    const error = this.state.error as null | Error;
    if (error) {
      return (
        <>
          <Loading />
          {/* <p style={{
            maxWidth: '100vw',
            fontSize: '20px',
            fontWeight: 'bold',
            wordWrap: 'break-word',
            position: 'fixed',
            top: '60%',
            left: '50%',
            transform: 'translateX(-50%)',
          }}>{String(error.message)}</p> */}
        </>
      );
    }
    return this.props.children;
  }
}
