import React from 'react';
import { Suspense } from 'react';
import './App.css';
import AllRoutes from './routes';
import 'dayjs/locale/zh-cn';
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';

const contentStyle: React.CSSProperties = {
  flex: 1,
};

function App(): JSX.Element {
  return (
    <Suspense>
      <Layout
        style={{ width: '100%', height: '100vh', backgroundColor: 'white' }}
      >
        <Content style={contentStyle}>
          <AllRoutes />
        </Content>
      </Layout>
    </Suspense>
  );
}

export default App;
