import React from "react";
import { Routes, Route } from "react-router-dom";
import { lazy, Suspense, useMemo } from "react";
import { useSelector } from "react-redux";
import type { RootState } from "@/store/index";
function App() {
  const routes = useSelector((state: RootState) => state.routes.routes);
  const ComponentWrapper = ({ component }: any) => {
    if (component.children > 0) {
      return (
        <Suspense>
          <Route path={component.path}>
            {ComponentWrapper(component.children)}
          </Route>
        </Suspense>
      );
    } else {
      const Component = useMemo(() => {
        return lazy(() => import(`../views/${component.element}/index.tsx`));
      }, [component]);
      return (
        <Suspense>
          <Component />
        </Suspense>
      );
    }
  };
  return (
    <Suspense>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<ComponentWrapper component={route} />}
          ></Route>
        ))}
      </Routes>
    </Suspense>
  );
}
export default App;
