import { HdcHostParams, HdcHostType } from '@/tools/hdc/HdcTypes';
import AES from '../aes';
import URI from 'urijs';
import { fetchTradeLocationCloudflareSingleton } from '../fetchTraceIP';
export class HdcHostModel implements HdcHostParams {
  port: {
    http: string[] | null;
    https: string[] | null;
  };
  type: HdcHostType;
  host: string;
  valid: boolean;
  nodeName: string;
  /** 网络延迟 null 表示没有的测速过  */
  latency: number | null;
  usage: string;
  // usage 国内用 0，国外用 7
  static USAGE_API = '0'
  static USAGE_RAW = '7'
  static USAGE = [HdcHostModel.USAGE_API, HdcHostModel.USAGE_RAW];
  // 根据 Location 切换 USAGE
  static switchUsageByLocation = async () => {
    const { location } = await fetchTradeLocationCloudflareSingleton()
    if (location == undefined) {
      // 如果获取不到 location，不切换 USAGE
      return;
    }
    // 如果 location 是 CN，切换 USAGE 到 0
    if (location == 'CN') {
      HdcHostModel.USAGE = ['0'];
    } else {
    // 如果 location 不是 CN，切换 USAGE 到 7
    HdcHostModel.USAGE = ['7'];
    }
    return location
  }
  // API 文档 https://cryptware.atlassian.net/wiki/spaces/TP1/pages/124551325/API
  // usage: 0(api), 1(app page), 2(recharge), 3(merchant), 4(agent), 5(operator),6(cdn)
  // cashier 页面只用 api 类型的 host
  constructor(info: HdcHostParams) {
    this.port = info.port;
    this.type = info.type;
    this.usage = info.usage;
    this.host = AES.decrypt(info.host);
    this.valid = info.valid;
    this.nodeName = info.nodeName;
    this.latency = null;
  }
  private toCommitBusiDstData() {
    const value = AES.encrypt(this.host);
    const dstData = {
      usage: this.usage,
      value,
    };
    return dstData;
  }
  private toCommitHdcDstData() {
    const dstData = AES.encrypt(this.host);
    return dstData;
  }
  private isDomainHost() {
    const domainPattern =
      /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
    return domainPattern.test(this.host);
  }
  private isIPHost() {
    return !this.isDomainHost();
  }
  private browserCurrentProtocol() {
    // 边界处理 https://caniuse.com/mdn-api_location_protocol
    // 在 Android 2.1 - 4.3 browser 中，有 location 对象，但是对象下没有 protocol 属性
    const protocol = window.location.protocol as 'http:' | 'https:' | undefined;
    // 当浏览器的 location 拿不到 protocol时，默认返回 http:
    if (protocol == undefined) {
      return 'http:';
    }
    return protocol;
  }
  private protocol() {
    const isIPHost = this.isIPHost();
    // IP Address 时，统一为 http
    if (isIPHost) {
      const IP_PROTOCOL = 'http:';
      return IP_PROTOCOL
    }
    // Business api 为 domain 沿用当前浏览器对应的协议
    const currentProtocol = this.browserCurrentProtocol();
    const name = currentProtocol.replace(':', '') as 'http' | 'https';
    // 如果当前协议对应的端口为空，返回另一个协议
    if (this.port[name] == null) {
      const protocol = name == 'http' ? 'https:' : 'http:';
      return protocol
    }
    // 正常情况下，返回当前协议
    return currentProtocol
  }
  private hdcPort() {
    const protocol = this.protocol();
    const key = protocol.replace(':', '') as 'http' | 'https';
    const list = this.port[key];
    if (list == null) {
      return null;
    }
    const index = Math.floor(Math.random() * list.length);
    const randomPort = list[index];
    return randomPort;
  }
  private busiProt() {
    const protocol = this.protocol();
    const key = protocol.replace(':', '') as 'http' | 'https';
    const list = this.port[key];
    if (list == null) {
      return null;
    }
    const index = Math.floor(Math.random() * list.length);
    const randomPort = list[index];
    return randomPort;
  }
  portFromHostType() {
    const port =
      this.type == HdcHostType.BUSINESS ? this.busiProt() : this.hdcPort();
    return port;
  }
  /** 上报时，将 host 转换成请求格式 */
  toCommitDstData() {
    const mapper = {
      [HdcHostType.BUSINESS]: this.toCommitBusiDstData.bind(this),
      [HdcHostType.HDC]: this.toCommitHdcDstData.bind(this),
    };
    const dstData = mapper[this.type]();
    return dstData;
  }
  /** 根据 host port 拼接，返回请求地址 */
  getUri() {
    const uri = new URI();
    uri.hostname(this.host);
    uri.fragment('');
    uri.pathname('graphql');
    uri.protocol(this.protocol());
    uri.port(this.portFromHostType()!);
    const urlStr = uri.toString();
    return urlStr;
  }
  getHealthUrl() {
    const baseUrl = new URI();
    baseUrl.hostname(this.host);
    baseUrl.fragment('');
    baseUrl.pathname('health');
    baseUrl.protocol(this.protocol());
    baseUrl.port(this.portFromHostType()!);
    const str = baseUrl.toString();
    return str;
  }
  disable() {
    this.valid = false;
  }
  setLatency(time: number) {
    this.latency = time;
  }
}
