import { HdcHostParams, HdcHostType, HdcNodeInfo } from '@/tools/hdc/HdcTypes';
import { HdcHostModel } from './HdcHostModel';

/**
 * HdcHostLocalCache 管理所有 Hdc 和 Business 的 Host
 * 1. 更新 Host 缓存
 * 2. 提供 Hdc 和 Business 当前可用的 Host
 * 3. 返回 本地缓存中，所有失效的 node. 用于上报。
 */
class HdcHostLocalCache {
  hdcList: HdcHostModel[];
  busiList: HdcHostModel[];

  hostFromDst(params: Omit<HdcHostParams, 'valid'>) {
    const h = new HdcHostModel({
      ...params,
      valid: true,
    });
    return h;
  }

  setup(nodeList: HdcNodeInfo[]) {
    this.updateList(nodeList);
  }
  constructor(nodeList: HdcNodeInfo[]) {
    this.hdcList = [];
    this.busiList = [];
    this.setup(nodeList);
  }

  private updateHdcList(info: HdcNodeInfo['hdc'], name: string) {
    const list = this.hdcList;
    if (info == null) {
      return;
    }
    const dst = info.dst;
    // 如果 HDC port 为空，跳过更新
    if (info.port.http == null && info.port.https == null) {
      console.log(`updateHdcList ${name} 的 HDC port 为空，跳过更新`);
      return;
    }

    for (const value of dst) {
      const host = value;
      const current = list.find((item) => item.host == host);
      if (current == undefined) {
        const h = this.hostFromDst({
          usage: 'HDC',
          host: host,
          nodeName: name,
          type: HdcHostType.HDC,
          port: info.port,
        });
        if (h.portFromHostType() == null) {
          console.log(
            `updateHdcList ${h.host} 的 HDC port 没有可用的，跳过更新`
          );
          continue;
        }
        list.push(h);
        console.log(`updateHdcList ${h.host} 已更新到缓存`);
        continue;
      }
      if (current.valid) {
        console.log(`updateHdcList ${current.host} 在缓存中已存在，跳过更新`);
      } else {
        console.log(`updateHdcList ${current.host} 在缓存中已失效，跳过更新`);
      }
    }
  }

  private updateBusiList(info: HdcNodeInfo['business'], name: string) {
    const list = this.busiList;
    const type = HdcHostType.BUSINESS;
    if (info == null) {
      return;
    }
    const dst = info.dst;
    console.log('HdcHostModel.USAGE', String(HdcHostModel.USAGE));
    for (const { value, usage, port } of dst) {
      // 如果 usage 不在 HdcHostModel.USAGE 中，跳过更新
      if (!HdcHostModel.USAGE.includes(usage)) {
        console.log('跳过 usage 为', usage)
        continue;
      }
      const host = value;
      const current = list.find((item) => item.host == host);
      if (current == undefined) {
        const h = this.hostFromDst({
          usage: usage,
          host: host,
          nodeName: name,
          type: type,
          port: port,
        });
        if (h.portFromHostType() == null) {
          console.log(
            `updateBusiList ${h.host} 的 Business port 没有可用的，跳过更新`
          );
          continue;
        }
        list.push(h);
        console.log(`updateBusiList ${h.host} 已更新到缓存`);
        continue;
      }
      if (current.valid) {
        console.log(`updateBusiList ${current.host} 在缓存中已存在，跳过更新`);
      } else {
        console.log(`updateBusiList ${current.host} 在缓存中已失效，跳过更新`);
      }
    }
  }

  getBusinessActiveHostList() {
    const activeHostList = this.busiList.filter((host) => {
      const isValid = host.valid;
      const isTested = host.latency != null;
      return isValid && isTested;
    });
    return activeHostList;
  }

  getVaildBusinessHost() {
    // 判断是否经过测速
    const activeHostList = this.getBusinessActiveHostList();

    if (activeHostList.length === 0) return undefined;

    return activeHostList.reduce((fastest, current) => {
      return current.latency! < fastest.latency! ? current : fastest;
    });
  }

  getVaildHost(type: HdcHostType) {
    const list = type == HdcHostType.BUSINESS ? this.busiList : this.hdcList;
    const valid = list.find((host) => host.valid);
    return valid;
  }

  /** 通过 QueryDomain 和 OSS 的结果，更新本地 host 缓存 */
  updateList(nodeList: HdcNodeInfo[]) {
    for (const info of nodeList) {
      this.updateHdcList(info.hdc, info.name);
      this.updateBusiList(info.business, info.name);
    }
  }

  /** 返回本地缓存中，所有失效的 node. 用于上报。 */
  reportList() {
    const hdcReportList = this.hdcList.map((item) => item.toCommitDstData());
    const busiReportList = this.busiList.map((item) => item.toCommitDstData());
    const nodes = JSON.stringify([
      {
        hdc: {
          port: [],
          dst: hdcReportList,
        },
        business: {
          port: [],
          dst: busiReportList,
        },
      },
    ]);
    return nodes;
  }
}

export default HdcHostLocalCache;
