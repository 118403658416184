import { create } from 'zustand'

interface HdcProgressStore {
  recordList: string[],
  hchd: () => void,
  htw: () => void,
  ah: () => void,
  oss: () => void,
  api: () => void,
}

export const HDC_COLOR_DICT = {
  H_CHD: '#F45757',
  H_TW: '#FE891E',
  OSS: '#2FAD0C',
  A_H: '#0087E8',
  API: '#7C67FA',
}

const useHdcProgress = create<HdcProgressStore>((set) => {
  const handleProgress = (color: string) => {
    set((state) => {
      return {
        recordList: [...state.recordList, color]
      }
    })
  }
  return {
    recordList: [],
    hchd: () => handleProgress(HDC_COLOR_DICT.H_CHD),
    htw: () => handleProgress(HDC_COLOR_DICT.H_TW),
    ah: () => handleProgress(HDC_COLOR_DICT.A_H),
    oss: () => handleProgress(HDC_COLOR_DICT.OSS),
    api: () => handleProgress(HDC_COLOR_DICT.API),
  }
})

// 在 React 组件外部调用
/** 记录 HDC 的执行过程 */
export const recordProgress = {
  hchd: useHdcProgress.getState().hchd,
  htw: useHdcProgress.getState().htw,
  ah: useHdcProgress.getState().ah,
  oss: useHdcProgress.getState().oss,
  api: useHdcProgress.getState().api,
}

export default useHdcProgress
