export default [
  {
    name: 'rps_chn_chd_hdc',
    hdc: {
      port: {
        http: ['80', '30071'],
        https: ['443', '30243'],
      },
      dst: [
        'G09H2dxTWnBrfhAgJuTH6U04qHQfHyLb1d0FAppNYdM=',
        'aEENqxG+Z1xu9OvkDFOBpA==',
        'muzoikzitFV+G/qETWqY0eHkIpyfm/UUcDFArH0ORLw=',
        'EE+JM4JJSOoVfwvWrKVjtg==',
      ],
    },
    business: null,
  },
  {
    name: 'rps_chn_tw_hdc',
    hdc: {
      port: {
        http: ['80', '30071'],
        https: ['443', '30243'],
      },
      dst: [
        "9jWjbLsMSfGauoTXyePsnw==",
        "JTpOL0qBnNJcmNhm60TRSg==",
        "GgIrBvAXUP0aLHl60iQkGg==",
        "iTvtCG7KD2uOblq1RVO88Q=="
      ],
    },
    business: null,
  },
];
