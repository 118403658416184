import HdcHostLocalCache from '@/tools/hdc/HdcNodeCache';
import { HdcHostType, HdcNodeInfo } from '@/tools/hdc/HdcTypes';
import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  HttpLink,
  InMemoryCache,
  NextLink,
  NormalizedCacheObject,
  Observable,
  Operation,
  from,
} from '@apollo/client';
import {
  QUERY_DOMAIN_QL,
  REPORT_DOMAIN_QL,
} from '@/api/graphqlQueryStatements/hdc.ql';
import { onError } from '@apollo/client/link/error';
import {
  HDCNodeName,
  HDC_BUILTIN_INFO,
  HDC_BUILTIN_OSS_INFO,
} from './HdcConst';
import { PromiseAny } from '..';
import { HdcHostModel } from './HdcHostModel';
import { recordProgress } from '@/store/HdcProgressStore';

const NoHostError = (type: HdcHostType) => {
  const mapper = {
    [HdcHostType.HDC]: 'hdc',
    [HdcHostType.BUSINESS]: 'business',
  };
  console.error(`当前 ${mapper[type]} 无可用 Host`);
  return new Error(`${mapper[type]} 服务器地址获取失败 无可用 Host`);
};

class HdcNetworkManager {
  nodeCache: HdcHostLocalCache;
  client: ApolloClient<NormalizedCacheObject>;
  isUpdatedFromOss: boolean;
  private updateListFromOssInstance: Promise<any> | null;
  private initUpdateTaskInstance: Promise<void> | null;
  speedTestInstance: Promise<HdcHostModel | undefined> | null;
  static ossList = HDC_BUILTIN_OSS_INFO;
  static env = process.env.HDC_ENV;
  constructor() {
    /** # HDC 流程:1 加载内置HDC地址 */
    /** # 根据当前用户地理位置，切换 API 的 Usage */
    this.setupUsage();
    this.nodeCache = this.loadHdcHostCacheFromLocal();
    this.client = this.setupClient();
    this.isUpdatedFromOss = false;
    /** # HDC 流程:2.1.b 获取阿里OSS文件，解析并更新缓存 */
    this.updateListFromOssInstance = null;
    this.initUpdateTaskInstance = null;
    this.speedTestInstance = null;
    console.log('HdcNetworkManager', this);
  }

  setupInitUpdate() {
    /** # HDC 流程:2.1 通过内置HDC地址请求HDC */
    this.initUpdateTaskInstance =
      this.initUpdateTaskInstance ||
      this.fetchNodesFromHdc().catch((e) => {
        /** # HDC 流程:2.1.b-1 若HDC节点都不可用, 更新过阿里oss文件数据 */
        const taskOss = this.fetchNodesFromOss();
        /** # HDC 流程:2.1.b-2 获取阿里OSS文件，解析并更新缓存 */
        taskOss.then(() => {
          /** # HDC 流程:2.1.b-3 再次更新覆盖缓存中的业务地址与HDC地址 */
          this.fetchNodesFromHdc();
        });
      });
    return this.initUpdateTaskInstance;
  }

  // 根据当前位置，切换使用的 API 地址
  setupUsage() {
    return HdcHostModel.switchUsageByLocation();
  }

  // 从 HDC 获取节点列表
  fetchNodesFromHdc() {
    return this.client
      .query({
        context: { isHdc: true },
        query: QUERY_DOMAIN_QL,
        variables: { env: HdcNetworkManager.env },
      })
      .then(async (res) => {
        const nodeList = res.data.queryDomainV2?.nodes || [];
        console.log('nodeList', nodeList);
        // 等待 setupUsage 完成
        await this.setupUsage();
        console.log('setupUsage await');
        this._updateCacheList(nodeList);
      });
  }

  private loadHdcHostCacheFromLocal() {
    const env = HdcNetworkManager.env;
    const envList = Object.keys(HDC_BUILTIN_INFO);
    if (env == undefined || !envList.includes(env)) {
      throw new Error(`<${env}> HDC_ENV 未定义`);
    }
    const data = HDC_BUILTIN_INFO[env as keyof typeof HDC_BUILTIN_INFO];
    return new HdcHostLocalCache(data);
  }

  private fetchNodesFromOss() {
    const ossList = HdcNetworkManager.ossList;
    // updateCacheFromOss 是一个单例，只会被执行一次
    this.updateListFromOssInstance =
      this.updateListFromOssInstance ||
      new Promise((resolve) => {
        recordProgress.oss();
        const taskList = ossList.map((url) =>
          fetch(url)
            .then((response) => response.text())
            .then((hdcJsonCfg) => {
              const config = JSON.parse(hdcJsonCfg);
              return config;
            })
            .catch(() => {
              // 如果没有获取到，就返回 null
              return null;
            })
        );
        return Promise.all(taskList)
          .then((configList) => {
            // 失败的 OSS Conifg 文件会变成 null
            const configValidList = configList.filter((item) => item != null);
            // 找到最新的 OSS Config 文件，只使用最新的，进行更新
            const latestConfig = configValidList.reduce((latest, current) => {
              const result = Number(latest.date) > Number(current.date);
              if (result) {
                return latest;
              } else {
                return current;
              }
            });
            const nodes = latestConfig.nodes;
            this._updateCacheList(nodes);
            resolve(true);
          })
          .catch(() => resolve(false))
          .finally(() => {
            this.isUpdatedFromOss = true;
          });
      });
    return this.updateListFromOssInstance;
  }

  private reportNodes() {
    const nodes = this.nodeCache.reportList();
    const host = this.nodeCache.getVaildHost(HdcHostType.HDC);
    if (host == undefined) {
      return;
    }
    const client = new ApolloClient({
      uri: host.getUri(),
      cache: new InMemoryCache(),
    });

    client
      .query({
        query: REPORT_DOMAIN_QL,
        variables: { nodes },
      })
      .then((res) => {
        const nodeList = res.data.QueryDomainV2?.nodes || [];
        this._updateCacheList(nodeList);
      });
  }

  private _updateCacheList(nodeList: HdcNodeInfo[]) {
    this.nodeCache.updateList(nodeList);
  }

  fetchHealth(host: HdcHostModel) {
    const url = host.getHealthUrl();
    const startTime = new Date().getTime();
    const r = fetch(url, {
      mode: 'cors',
    })
      .then(async (response) => {
        console.log('response', response)
        if (!response.ok) {
          throw new Error(`${url} 响应失败`);
        }
        type ResponseData = {
          data: string | '1';
          code: number;
          msg: string;
        }
        const json = await response.json() as ResponseData
        if (json?.data != '1') {
          throw new Error(`${url} 响应失败`);
        }
        const endTime = new Date().getTime();
        const duration = endTime - startTime;
        host.setLatency(duration);
        return host;
      })
      .catch(() => {
        host.disable();
        return Promise.reject();
      });
    return r;
  }

  private _resetSpeedTestInstance() {
    this.speedTestInstance = null;
  }

  private _speedTest() {
    const list = this.nodeCache.busiList.filter((hots) => hots.valid);
    const taskList = list.map((host) => this.fetchHealth(host));
    return PromiseAny(taskList).catch(() => undefined);
  }

  async speedTest() {
    if (this.speedTestInstance == null) {
      this.speedTestInstance = this._speedTest();
      recordProgress.ah();
    }
    return this.speedTestInstance;
  }

  private getVaildHost = async (hostType: HdcHostType) => {
    if (hostType == HdcHostType.HDC) {
      const result = this.nodeCache.getVaildHost(hostType);
      return result;
    }
    const result = await this.speedTest();
    return result;
  };

  private setupClient() {
    const hdcLink = new ApolloLink(
      (operation: Operation, forward: NextLink) => {
        let errorFlag = false;
        const o = new Observable<FetchResult>((observer) => {
          const request = async () => {
            const context = operation.getContext();
            const isHdc = context.isHdc;
            const hostType = isHdc ? HdcHostType.HDC : HdcHostType.BUSINESS;
            const currentHost = await this.getVaildHost(hostType);
            // 如果 currentHost 为 undefined
            // 说明没有可以用的 Host
            if (currentHost == undefined) {
              if (this.isUpdatedFromOss) {
                return observer.error(NoHostError(hostType));
              }
              const isSuccess = await this.fetchNodesFromOss();
              this._resetSpeedTestInstance();
              if (isSuccess) {
                request();
              }
              return;
            }
            if (isHdc && operation.operationName == 'QueryDomain') {
              const name = currentHost.nodeName;
              switch (name) {
                case HDCNodeName.chd:
                  recordProgress.hchd();
                  break;
                case HDCNodeName.tw:
                  recordProgress.htw();
                  break;
                default:
                  recordProgress.hchd();
                  break;
              }
            } else {
              recordProgress.api();
            }
            const uri = currentHost.getUri();
            operation.setContext(({ headers = {} }) => {
              const headersConfig: any = {
                ...headers,
              };
              if (!isHdc) {
                headersConfig.Usage = currentHost.usage;
              }
              return {
                uri,
                headers: headersConfig,
              };
            });
            forward(operation).subscribe({
              next: (result) => observer.next(result),
              error: (error) => {
                errorFlag = true;
                currentHost.disable();
                request();
              },
              complete: () => {
                if (errorFlag) {
                  this.reportNodes();
                }
                return observer.complete();
              },
            });
          };
          request();
        });
        return o;
      }
    );
    const httpLink = new HttpLink({
      fetch(uri, options) {
        const TIMEOUT = 5000;
        return new Promise((resolve, reject) => {
          const timer = setTimeout(() => {
            reject(new Error(`请求 ${TIMEOUT} 毫秒超时`));
          }, TIMEOUT);
          fetch(uri, options)
            .then((response) => {
              clearTimeout(timer);
              resolve(response);
            })
            .catch((error) => {
              clearTimeout(timer);
              reject(error);
            });
        });
      },
    });
    const errorLink = onError((errorResponse) => {
      console.log('errorResponse', errorResponse);
      console.log('HdcNetworkManager', this);
    });
    return new ApolloClient({
      link: from([errorLink, hdcLink, httpLink]),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
        },
        query: {
          fetchPolicy: 'no-cache',
        },
      },
    });
  }
}

export default HdcNetworkManager;
