const isiOS = /(iPhone|iPod|iPad)/i.test(navigator.userAgent);
const isWebview = /(Windows)/i.test(navigator.userAgent);

const isBroswer = /(Windows)/i.test(navigator.userAgent);
const OOS = 'https://yzpay-app.oss-ap-southeast-6.aliyuncs.com/';
const PromiseAny = <T>(promises: Promise<T>[]): Promise<T> => {
  return new Promise((resolve, reject) => {
    let rejectionCount = 0;
    const totalPromises = promises.length;
    for (let promise of promises) {
      promise
        .then((value) => resolve(value))
        .catch(() => {
          rejectionCount++;
          if (rejectionCount === totalPromises) {
            reject(new Error('PromiseAny 全部失败'));
          }
        });
    }
  });
};
export { isiOS, OOS, isBroswer, PromiseAny };
