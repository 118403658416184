import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice({
  name: 'root',
  initialState: {
    routes: [
      {
        path: '/',
        title: 'YZPay 充值订单',
        element: 'home',
        children: [],
      },
      {
        path: '/IOSSetting',
        title: '更改iOS设备DNS设置',
        element: 'IOSSetting',
        children: [],
      },
      {
        path: '/download',
        title: '安卓下载',
        element: 'download',
        children: [],
      },
      {
        path: '/addPayment',
        title: '添加支付方式',
        element: 'addPayment',
        children: [],
      },
      {
        path: '/helpCenter',
        title: '添加支付方式',
        element: 'helpCenter',
        children: [],
      },
      {
        path: '/buy',
        title: '买',
        element: 'buy',
        children: [],
      },
      {
        path: '/realName',
        title: '实名',
        element: 'realName',
        children: [],
      },
      {
        path: '/sell',
        title: '卖',
        element: 'sell',
        children: [],
      },
      {
        path: '/iosDownload',
        title: 'ios下载',
        element: 'iosDownloadHelpPage',
        children: [],
      },
      {
        path: '/download_install',
        title: '如何下载并安装',
        element: 'downloadInstall',
        children: [],
      },
      {
        path: '/login_register',
        title: '如何登录与注册',
        element: 'loginRegister',
        children: [],
      },
      {
        path: '*',
        title: '',
        element: 'NotFound',
        children: [],
      },
      {
        path: '/handbook',
        title: '',
        element: 'handbook'
      },
      {
        path: '/handbookDownload/:type',
        title: '',
        element: 'handbookDownload'
      }
    ]
  },
  reducers: {
    changeRoutes: (state, action) => {
      state.routes = action.payload;
      return state;
    },
  },
});

export const { changeRoutes } = counterSlice.actions;
export default counterSlice.reducer;
