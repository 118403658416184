import App from '@/App';
import store from '@/store';
import {
  ApolloProvider,
} from '@apollo/client';
import 'lib-flexible/flexible.js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import viewportUnitsBuggyfill from 'viewport-units-buggyfill';
import { ErrorBoudary, setupErrorListener } from './components/ErrorBoudary';
import HdcNetworkManager from './tools/hdc';
import './index.css';
// 初始化插件
viewportUnitsBuggyfill.init();
// 初始化错误监听
setupErrorListener()
// 初始化网络管理器
export const hdcNetworkManager = new HdcNetworkManager();
const rootElement = document.getElementById('root') as HTMLElement;
ReactDOM.createRoot(rootElement).render(
  <ErrorBoudary>
    <React.StrictMode>
      <Provider store={store}>
        <Router>
          <ApolloProvider client={hdcNetworkManager.client}>
            <App />
          </ApolloProvider>
        </Router>
      </Provider>
    </React.StrictMode>
  </ErrorBoudary>
);
