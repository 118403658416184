import { PromiseAny } from '.';

const fetchTradeLocationCloudflare = () => {
  const uri = 'https://www.cloudflare.com/cdn-cgi/trace';
  // curl https://www.cloudflare.com/cdn-cgi/trace
  // 可以看一下结构
  const extractLocationAndIp = (text: string) => {
    const locationLineStartIndex = text.indexOf('loc=');
    const locationLineEndIndex = text.indexOf('\n', locationLineStartIndex);
    const locationLine = text.slice(
      locationLineStartIndex,
      locationLineEndIndex
    );
    const location = locationLine.split('=')[1];
    const ipLineStartIndex = text.indexOf('ip=');
    const ipLineEndIndex = text.indexOf('\n', ipLineStartIndex);
    const ipLine = text.slice(ipLineStartIndex, ipLineEndIndex);
    const ip = ipLine.split('=')[1];
    console.log('cloudflare ip 获取成功:', ip);
    console.log('cloudflare location 获取成功:', location);
    return {
      ip,
      location,
    };
  };
  return fetch(uri, { mode: 'cors' })
    .then((response) => response.text())
    .then(extractLocationAndIp);
};

// 将 fetchTradeLocationCloudflare 封装成一个单例
// 1. 如果已经有了，就直接返回
// 2. 如果没有，就 fetch 一下
// 3. 如果 fetch 失败，就返回 undefined
// 4. 如果 fetch 成功，就将 { ip, location } 存起来
export const fetchTradeLocationCloudflareSingleton = (() => {
  let result: Promise<{ip?: string, location?: string}> | undefined;
  return () => {
    if (result != undefined) {
      return result;
    }
    result = fetchTradeLocationCloudflare().catch(() => {
      return {};
    });
    return result;
  };
})();

 const fetchTradeCloudflare = () => {
  return fetchTradeLocationCloudflareSingleton().then((result) => {
    if (result == undefined) {
      return Promise.reject();
    }
    if (result.ip == undefined) {
      return Promise.reject();
    }
    return result.ip;
  });
};

const fetchTradeIpApi = () => {
  const uri = 'https://ident.me';
  // curl https://ident.me
  // 可以看一下结构
  const extractIP = (ip: string) => {
    if (ip == undefined) {
      return Promise.reject();
    }
    console.log('https://ident.me ip 获取成功:', ip);
    return ip;
  };

  return fetch(uri, { mode: 'cors' })
    .then((response) => response.text())
    .then(extractIP);
};

const fetchTradeRPS = () => {
  const uri = 'https://a2.xdv5efw.xyz:32201/nnnipv/';
  // curl https://a2.xdv5efw.xyz:32201/nnnipv
  // 可以看一下结构
  const extractIP = (text: string) => {
    const ip = text.trim();
    console.log('RPS ip 获取成功:', ip);
    return ip;
  };
  return fetch(uri, { mode: 'cors' })
    .then((response) => response.text())
    .then(extractIP);
};

const fetchTraceIP = async () => {
  const ip = await PromiseAny([
    fetchTradeIpApi(),
    fetchTradeCloudflare(),
  ]).catch(() => {
    return fetchTradeRPS();
  });
  return ip;
};

export default fetchTraceIP;
