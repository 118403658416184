import { configureStore } from '@reduxjs/toolkit';
import counterReducers from './modules/reducers';
import { increment, decrement } from './modules/reducers'
import listReducers from './modules/list'
import { pageAdd, pageLess } from './modules/list'
import rootReducers from './modules/root'
import { setToken, clearToken } from './modules/root'
import { changeRoutes } from './modules/routes'
import routes from './modules/routes'
const store = configureStore({
  reducer: {
    root: rootReducers,
    counter: counterReducers,
    list: listReducers,
    routes: routes
  }
});
// 导出ts类型
export type RootState = ReturnType<typeof store.getState>;
// 导出dispatch函数
export { increment, decrement, pageAdd, pageLess, setToken, clearToken, changeRoutes }
export default store;
