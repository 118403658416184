export type BusiDstData = {
  usage: string;
  value: string;
  port: HdcPortDict;
};

export type HdcPortDict = {
  http: string[] | null;
  https: string[] | null;
};

/** HdcNode 接口的结构体 */
export interface HdcNodeInfo {
  name: string;
  hdc: {
    port: HdcPortDict;
    dst: string[];
  } | null;
  business: {
    dst: BusiDstData[];
  } | null;
}

/** Host 分类 */
export enum HdcHostType {
  HDC,
  BUSINESS,
}

export interface HdcHostParams {
  /** 端口列表 */
  port: {
    http: string[] | null;
    https: string[] | null;
  };
  usage: string;
  /** 类型 */
  type: HdcHostType;
  host: string;
  /** 是否可用 */
  valid: boolean;
  /** 节点名称 */
  nodeName: string;
}
