import CryptoJS from 'crypto-js';

const KEY = CryptoJS.enc.Utf8.parse('YZPPomeloEncrypt')

const CIPHER_OPTION = {
  mode: CryptoJS.mode.ECB,
  padding: CryptoJS.pad.Pkcs7
}

/** 加密 */
function encrypt(text: string) {
  const encrypted = CryptoJS.AES.encrypt(text, KEY, CIPHER_OPTION);
  return encrypted.toString();
}

/** 解密 */
function decrypt(text: string) {
  const decrypted = CryptoJS.AES.decrypt(text, KEY, CIPHER_OPTION);
  return decrypted.toString(CryptoJS.enc.Utf8);
}

const AES = {
  /** 加密 */
  encrypt,
  /** 解密 */
  decrypt,
}

export default AES
