import { createSlice } from '@reduxjs/toolkit';

const counterSlice = createSlice({
  name: 'list',
  initialState: {
    page: 0
  },
  reducers: {
    pageAdd: (state) => {
      state.page = state.page + 1
      return state
    },
    pageLess: (state) => {
      state.page = state.page - 1
      return state
    }
  },
});

export const { pageAdd, pageLess } = counterSlice.actions;
export default counterSlice.reducer;
